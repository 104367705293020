<template>
<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div>

    <b-col md="12" class="my-2">
      <custom-input
        v-if="variables"
        class="my-1"
        id="custom-input-r1"
        :possibleValues="variables"
        v-model="stopData.message.register_1"
        advanced
      >
        <template #label>
          Error Message
          <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
          <span class="float-right text-right mx-25 text-secondary">what will be returned if an error occurs</span>
        </template>
      </custom-input>
        <div v-else class="mb-2">
          <b-skeleton class="mb-25" width="25%" height="12px" />
          <b-skeleton class="mb-25" width="100%" height="37px" />
          <b-skeleton class="mb-25" width="25%" height="14px" />
        </div>
    </b-col>

    <h3 class="m-1">Agents</h3>
    <actions-reorder-list
      :key="getID(`actions-reorder-list-${version}`)"
      class="mx-1"
      :middlewareID="middlewareID"
      v-model="agents"
      @agentAdded="version++"
      @agentEdited="version++"
      @agentDeleted="version++"
    />
  </div>
</middleware-sidebar-outline> 
</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar,
  BSkeleton,
  BLink,
  BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue"
import HelperTooltip from '@/layouts/components/HelperTooltip';
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';

import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"

import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'


  export default {
    mixins: [MiddlewaresMixin],
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BAvatar,
      BCardBody,
      ActionsReorderList,
      BSkeleton,
      CustomInput,
      HelperTooltip,
      BLink,
      BCollapse,
      MiddlewareSidebarOutline,
    },
    props: {
      i18nKey: {
        type: String,
        default: "custom"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        middlewareType: new Middlewares().items.find(el=> el.id == 40),
        variables: undefined,
        variant_map: undefined,
        isSaving: false,
        version: 0,
        stopData: {
          message: null
        },

        nameField:{
          message: 'MESSAGE',
        },

        agentList: [],
        isSaving: false,
      }
    },
    computed: {
      agents: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.initializeAgents()
        
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      save(){
        this.buildAgents();
      },
      buildAgents() {
        this.isSaving = true;
        this.agentList = [];

        Object.keys(this.stopData).forEach((key) => {
          if (this.stopData[key].register_1) {
            this.agentList.push(DefaultAgent.defineToAPI(this.stopData[key], this.middlewareID));
          }
        })
        
        this.saveAgents();
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.isSaving = false;
            this.$emit("saved", response.data);
          })
          .catch((error) => {
            this.isSaving = false;
          });
      },
      initializeAgents() {
        Object.keys(this.stopData).forEach((key) => {
          this.stopData[key] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: "7",
                  value: 'An Error Occured' 
                },
                register_2: {
                },
                register_destiny: {
                  source: "5",
                  value: this.nameField[key]
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              source: "5",
              value: this.nameField[key],
              type: this.defaultAgentActionID,
              multiple: false,
              from: {
                source: "register_destiny",
                value: "register_destiny"
              },
            },
            this.agents
          );
        })
      },
      sidebarShown() {
this.$emit("shown")
        this.isSaving = false;
        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
    }

  }
</script>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>



<style lang="scss" scoped>
.agent-text {
  display: block !important;
  width: 300px;
}
</style>
<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.toggle-header-bar,.toggle-header-bar:focus, .toggle-header-bar:active {
	background-color: #151925 !important;
	box-shadow:  none;
	border: none;
	border-radius: 0;
}
.toggle-header-bar:hover{
  box-shadow: 0 0 10px black !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}

</style>